import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import { MetaTags } from 'react-meta-tags';

class HomePage extends Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }

    componentDidMount = async () => {
        const stats = 
        this.setState({})
    }

    render = () => {
        const { stats, statsActive} = this.state;
        return (
            <div className='page'>
                <MetaTags>
                    <title>Home</title>
                    <meta name="description" content="Stay up to date with today´s trends" />
                    <meta property="og:title" content="Home" />
                </MetaTags>
                {/* Home Data */}
                <div className='margin'>
                    <h5 className='grey text-center'>It runs! 🎉</h5>
                </div>
            </div>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(HomePage);
