const make = ["BMW", "Audi", "Toyota", "Honda", "Ford"];
const model = ["X5", "A4", "Camry", "Accord", "F150"];
const year = [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020];
const price = [10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000];
const version = ["Standard", "Premium", "Luxury"]; 
const power = [100, 150, 200, 250, 300, 350, 400, 450, 500];
const engine = [1.0, 1.2, 1.4, 1.6, 1.8, 2.0, 2.2, 2.4, 2.6, 2.8, 3.0, 3.2, 3.4, 3.6, 3.8, 4.0, 4.2, 4.4, 4.6, 4.8, 5.0, 5.2, 5.4, 5.6, 5.8, 6.0];
const fuel = ["Gasoline", "Diesel", "Electric", "Hybrid"];
const transmissions = ["Automatic", "Manual"];
const doors = [2, 3, 4, 5];
const color = ["Red", "Blue", "Green", "Black", "White"];
const seats = [2, 4, 5, 7, 9];
const vehicleType = ["Sedan", "Coupe", "SUV", "Truck", "Van"];
const country = ["Portugal", "USA", "Germany", "Japan", "Italy", "UK"];
const vatDeductible = ["Yes", "No"];
const interiorMaterial = ["Leather", "Cloth", "Alcantara"];
const powerUnit = ["HP", "kW"];

const equipment = {
    security : ['ABS', 'ESP', 'TCS', 'Airbag', 'Alarm', 'Immobilizer', 'Central Locking', 'ISOFIX'],
    comfort : ['Climate Control', 'Power Steering', 'Electric Windows', 'Electric Mirrors', 'Heated Seats', 'Cruise Control', 'Parking Sensors', 'Rear Camera'],
    multimedia : ['Navigation', 'Bluetooth', 'USB', 'AUX', 'CD Player', 'MP3', 'DVD Player', 'TV', 'Touch Screen'],
    exterior : ['Alloy Wheels', 'Metallic Paint', 'Roof Rails', 'Tow Bar', 'Sunroof', 'Panoramic Roof', 'Xenon Lights', 'LED Lights'],
    other : ['Service Book', 'Spare Key', 'First Owner', 'Garage Kept', 'Non-Smoker Vehicle', 'Tuning', 'Modified', 'Classic Car']
}


const allFiltersMock = {
    make,
    model,
    color,
    year,
    price,
    version,
    power,
    engine,
    fuel,
    transmissions,
    doors,
    seats,
    vehicleType,
    country,
    vatDeductible,
    interiorMaterial,
    equipment
}

export default allFiltersMock;