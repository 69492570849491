export const formToDbCar = (form) => {
    let car = form;
    let keys = car.vehicle.keys();
    for(let i = 0; i < keys.length; i++){
        let key = keys[i];
        if(car.vehicle[key] == 'Yes')
            car.vehicle[key] = false;
        if(car.vehicle[key] == 'No')
            car.vehicle[key] = true;
        if(car.vehicle[key] == '2/3')
            car.vehicle[key] = 3;
        if(car.vehicle[key] == '4/5')
            car.vehicle[key] = 5;
    }
    return car;
}

export const vehicle = {
    make : '',
    model : '',
    avatar : '',
    price : 0,
    vat : 0,
    year : '',
    version : '',
    color : '',
    transmission : 'Manual',
    fuel : '',
    vehicleType : '',
    other : [],
    security : [],
    multimedia : [],
    interior : [],
    doors : '2/3',
    power : '', 
    powerUnit : '',
    engine : '',
    seats : '',
    mileage : '',
    interiorMaterial : [],
    country : '',
    photos : [],
    vatDeductible : 'Yes',
    firstOwner : 'Yes',
    nonSmoking : 'Yes',
    serviceBook : 'Yes',
    guarantee : 'Yes',
    accidentFree : 'Yes',
    demonstrationVehicle : 'Yes',
    rentalCar : 'Yes',
    taxi : 'Yes',
    drivingSchoolCar : 'Yes',
    co2Emission: ''
};

export const contact = {
    firstName : '',
    lastName : '',
    phoneNumber : '',
    email : '',
    city : '',
    postalCode : '',
    street : '',
    houseNumber : '',
    country : '',
}

export const photos = {
leftSideExterior : [],
rightSideExterior : [],
frontExterior : [],
backExterior : [],
leftSideInterior : [],
rightSideInterior : [],
frontInterior : [],
backInterior : [],
other1 : [],
other2 : []
}
