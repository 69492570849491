/* Create Hero Section with Image and Text */
import React from 'react';
import './index.scss';
import { CheckCircle, EnhancedEncryptionOutlined, FavoriteBorderOutlined, SupportOutlined } from '@mui/icons-material';
import InformationCard from '../../components/InformationCard';


const faq = [
    {
        question : 'How does Versicar work?',
        answer : 'Versicar is a platform that allows you to buy a car online. We inspect the car and deliver it to you.'
    },
    {
        question : 'How do I buy a car on Versicar?',
        answer : 'You can choose your car online and we will deliver it to you.'
    },
    {
        question : 'How do I sell my car on Versicar?',
        answer : 'You can sell your car on Versicar by contacting us.'
    }
]


const faq2 = [
    {
        question : 'How does Versicar work?',
        answer : 'Versicar is a platform that allows you to buy a car online. We inspect the car and deliver it to you.'    
    },
    {
        question : 'How do I buy a car on Versicar?',   
        answer : 'You can choose your car online and we will deliver it to you.'    
    }
]

const faq3 = [
'How does Versicar work?',
'How do I buy a car on Versicar?',
'How do I sell my car on Versicar?',
'How does Versicar work?',
]


const ServiceHero = () => {

    return (
        <div>            
            <div className='section box-background'>
                <div className='container'>
                    <h4 className='grey'>Historic and physical inspection</h4>
                    <h2>A guaranteed purchase</h2>
                    <div className='grid-33'>
                        {faq.map((item, index) => {
                            return <InformationCard title={index + 1} subTitle={item.question} content={item.question}/>
                        })}
                    </div>
                </div>   
            </div>
            <div className='section'>
                <div className='container'>
                    <h4 className='grey'>Delivery</h4>
                    <h2> Delivery from all of Europe </h2>
                    <div className='grid-50'>
                        {faq2.map((item, index) => {
                            return <InformationCard subTitle={item.question} content={item.question}/>
                        })}
                    </div>
                </div>
            </div>
            <div className='section box-background'>
                <div className='container'>
                    <h4 className='grey'>Import and registration</h4>
                    <h2> We take care of everything </h2>   
                    <div className='small-container'></div>
                    {faq3.map((item, index) => {
                        return (
                            <div key={index}>
                                <CheckCircle className='inline green' style={{marginLeft : 20}}/>
                                <h4 className='inline'>{item}</h4>
                            </div>)
                    })}
                </div>    
            </div>
        </div>

    )
}   

export default ServiceHero;