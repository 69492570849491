import ConnectionAPI from '../app/api/';

const priceFrom = [1000, 2000, 3000, 4000, 5000, 10000, 20000, 30000, 40000, 50000];
const priceTo = [1000, 2000, 3000, 4000, 5000, 10000, 20000, 30000, 40000, 50000];
const registrationFrom = [ 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];
const registrationTo = registrationFrom
const mileageFrom = [0, 10000, 20000, 30000, 40000, 50000];
const mileageTo = [10000, 20000, 30000, 40000, 50000];
const powerFrom = [0, 100, 200, 300, 400, 500];
const powerTo = [100, 200, 300, 400, 500];

let _makeInfo = [];
let _modelInfoResponse = [];
let _modelInfo = [];
let getMakes = async () => {
    let res = [];
    if (_makeInfo.length == 0) {
        const response = await ConnectionAPI.getExistingMakes();
        if (response.status === 200) {
            const { makes } = response.message;
            _makeInfo = makes;
        }

        let infoMap = {};
        let keys = Object.keys(_makeInfo);
        for (let i = 0; i < keys.length; i++) {
            infoMap[_makeInfo[keys[i]].type] = _makeInfo[keys[i]].id;
        }
        _makeInfo = infoMap;
    }

    let keys = Object.keys(_makeInfo);
    for (let i = 0; i < keys.length; i++) {
        res.push(keys[i]);
    }
    return res;
}

let getModels = async (current_make) => {
    let res = [];
    if (_modelInfo.length == 0) {
        const response = await ConnectionAPI.getExistingModels();
        if (response.status === 200) {
            const { result } = response.message;
            _modelInfo = result;
        }
        _modelInfoResponse = _modelInfo;
        let infoMap = {};
        let keys = Object.keys(_modelInfo);
        for (let i = 0; i < keys.length; i++) {
            res.push(_modelInfo[keys[i]].type);
            infoMap[_modelInfo[keys[i]].type] = _modelInfo[keys[i]].id;
        }
        _modelInfo = infoMap;
    }

    const searchMake = current_make != null && current_make != '';
    let makeId = -1;
    if(searchMake)
        makeId = _makeInfo[current_make];

    let newModels = [];
    let keys = Object.keys(_modelInfoResponse);

    for (let i = 0; i < keys.length; i++) {
        if (makeId == -1 || _modelInfoResponse[keys[i]].make == makeId) {
            newModels.push(_modelInfoResponse[keys[i]].type);
        }
    }
    res = newModels;

    return res;
}

let _tranmissionTypes = {};
let getTransmissionTypes = async (language) => {

    if(!_tranmissionTypes[language]){
        const response = await ConnectionAPI.getTransmissionTypes();

        let res = null;
        if (response.status === 200) {
            const { result } = response.message;
            res = result;
        }

        let aux = {};
        for (let i = 0; i < res.length; i++) {
            aux[res[i][language]] = res[i].id;
        }

        _tranmissionTypes[language] = aux;
    }

    const types = {};
    let keys = Object.keys(_tranmissionTypes[language]);
    for(let i = 0; i < keys.length; i++){
        types[keys[i]] = _tranmissionTypes[language][keys[i]].id;
    }
    
    return [Object.keys(types),types];
}

let _fuelTypes = {};
let getFuelTypes = async (language) => {

    if(!_fuelTypes[language]){
        const response = await ConnectionAPI.getFuelTypes();

        let res = null;
        if (response.status === 200) {
            const { result } = response.message;
            res = result;
        }

        let aux = {};
        for (let i = 0; i < res.length; i++) {
            aux[res[i][language]] = res[i].id;
        }

        _fuelTypes[language] = aux;
    }

    const types = {};
    let keys = Object.keys(_fuelTypes[language]);
    for(let i = 0; i < keys.length; i++){
        types[keys[i]] = _fuelTypes[language][keys[i]].id;
    }
    
    return [Object.keys(types),types];
}

let _powerUnits = {};
let getPowerUnits = (t) => {
    let res = {}
    if(Object.keys(_powerUnits).length !== 0){
        res = _powerUnits;
    }else if(t){
        res[t('hp').toUpperCase()] = 'HP';
        res['kW'] = 'kW';
        _powerUnits = res;
    }

    return _powerUnits;
}

//const make = ["BMW", "Audi", "Toyota", "Honda", "Ford","Tesla"];
//const model = ["X5", "A4", "Camry", "Accord", "F150"];
const color = ["Black", "White", "Grey", "Red", "Blue", "Green", "Beige", "Yellow", "Orange", "Purple", "Brown", "Gold", "Silver", "Default"];
const version = ["Standard", "Premium", "Luxury"];
const engine = [1.0, 1.2, 1.4, 1.6, 1.8, 2.0, 2.2, 2.4, 2.6, 2.8, 3.0, 3.2, 3.4, 3.6, 3.8, 4.0, 4.2, 4.4, 4.6, 4.8, 5.0, 5.2, 5.4, 5.6, 5.8, 6.0];
const doors = [2, 3, 4, 5];
const seats = [2, 4, 5, 7, 9];
const vehicleType = ["Sedan", "Coupe", "SUV", "Truck", "Van"];
const country = ["Portugal", "USA", "Germany", "Japan", "Italy", "UK"];
const vatDeductible = ["Yes", "No"];
const interiorMaterial = ["Leather", "Cloth", "Alcantara"];

const equipment = {
    security: ['ABS', 'ESP', 'TCS', 'Airbag', 'Alarm', 'Immobilizer', 'Central Locking', 'ISOFIX'],
    comfort: ['Climate Control', 'Power Steering', 'Electric Windows', 'Electric Mirrors', 'Heated Seats', 'Cruise Control', 'Parking Sensors', 'Rear Camera'],
    multimedia: ['Navigation', 'Bluetooth', 'USB', 'AUX', 'CD Player', 'MP3', 'DVD Player', 'TV', 'Touch Screen'],
    exterior: ['Alloy Wheels', 'Metallic Paint', 'Roof Rails', 'Tow Bar', 'Sunroof', 'Panoramic Roof', 'Xenon Lights', 'LED Lights'],
    other: ['Service Book', 'Spare Key', 'First Owner', 'Garage Kept', 'Non-Smoker Vehicle', 'Tuning', 'Modified', 'Classic Car']
}

export const getFilterValues = async (t,language,form) => {
    //fill values if they are necessary (this might be in case of a refresh)
    await filtersSearch();
    let res = structuredClone(form);
    if(!_tranmissionTypes[language])
        res.transmission = '';
    else 
        res.transmission = _tranmissionTypes[language][form.transmission] ?? '';

    res.make = _makeInfo[form.make] ?? '';
    res.model = _modelInfo[form.model] ?? '';

    if(!_fuelTypes[language])
        res.fuel = '';
    else 
        res.fuel = _fuelTypes[language][form.fuel] ?? '';

    if(form.powerUnit)
        res.powerUnit = _powerUnits[form.powerUnit]

    return res;
}

export const filtersSearch = async (t,language,current_make = null) => {
    const make = await getMakes();
    const model = await getModels(current_make);
    const transmission = (await getTransmissionTypes(language))[0];
    const fuel = (await getFuelTypes(language))[0];
    const powerUnit = getPowerUnits(t);
    return {
        make,
        model,
        color,
        priceFrom,
        priceTo,
        powerFrom,
        powerTo,
        registrationFrom,
        registrationTo,
        mileageFrom,
        mileageTo,
        version,
        engine,
        fuel,
        transmission,
        doors,
        seats,
        vehicleType,
        country,
        vatDeductible,
        interiorMaterial,
        equipment,
        powerUnit
    }
}
