import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Image from '../../components/Image';
import { CarCrash, Done, FavoriteBorderOutlined, FavoriteOutlined } from '@mui/icons-material';
import Button from '../../components/Button';
import UrlNavigatorSingleton from '../../lib/UrlNavigator';
import ConnectionAPI from '../../api';
import { Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ImageGallery from "react-image-gallery";
import { formatNumber } from '../../../utils/number.tsx';
import ImageDisplayer from '../../components/ImageDisplayer/index';
import { defaultCar, fuelIcon, locationIcon, mileageIcon, phone, powerIcon, registrationDateIcon, transmissionIcon } from '../../../assets/images';
import { withTranslation } from 'react-i18next';
import { pixelCarPageView, pixelUserFavorite, pixelUserMobilePhone, pixelUserOrder } from '../../../utils/pixel.js';
import { isMobile } from '../../../utils/utils.js';
import { withNavigate } from '../../../utils/navigator.js';
import { FROM_CAR_REGISTER_SESSION_STORAGE_KEY } from '../AppPage/SignUpPage/index.js';

class CarPage extends Component {
    constructor(props) { super(props); this.state = { showContact:false, loading: false, car: {} } }

    componentDidMount() {
        this.getCar();
        //check if the user came from login and wants to have add favorite
        let additionalCarInfo = sessionStorage.getItem(FROM_CAR_REGISTER_SESSION_STORAGE_KEY);
        if(additionalCarInfo){
            const info = JSON.parse(additionalCarInfo);
            if(info.favorite){
                this.addFavorite({carId: info.car});
            }
            //delete it
            sessionStorage.removeItem(FROM_CAR_REGISTER_SESSION_STORAGE_KEY);
        }

        pixelCarPageView(this.props.user.email,this.state.car.id);
    }

    componentDidUpdate(prevProps) {
        // Check if a specific prop has changed
        if (this.props.user !== prevProps.user) {
            // Props have changed, perform an action here
            this.setFavoriteStatus();
        }
    }

    setFavoriteStatus = async () => {
        if (Object.keys(this.props.user).length !== 0) {
            const { id } = UrlNavigatorSingleton.getFields(['id']);

            const { car } = this.state;

            let response_my_car = await ConnectionAPI.myCarDetails({ id });
            car.isFavorite = response_my_car.message.isFavorite;

            this.setState({ car: car });
        }
    }

    getCar = async () => {
        /* Get Car Id from Query Params */
        const { id } = UrlNavigatorSingleton.getFields(['id']);
        /* Get Car from API */
        let response = await ConnectionAPI.getCar({ id });
        let { car } = response.message;
        
        if (Object.keys(this.props.user).length !== 0) {
            let response_my_car = await ConnectionAPI.myCarDetails({ id });
            car.isFavorite = response_my_car.message.isFavorite;
        }
        this.setState({ car: car });
    }

    addFavorite = async ({carId = null}) => {
        const { user } = this.props;

        let id  = null;
        if(carId)
            id = carId;
        else 
            id = this.state.car.id;

        if(Object.keys(user).length === 0){
            //just to be safe, delete it first
            sessionStorage.removeItem(FROM_CAR_REGISTER_SESSION_STORAGE_KEY);
            sessionStorage.setItem(FROM_CAR_REGISTER_SESSION_STORAGE_KEY,  JSON.stringify({'car': id, 'favorite': true}));
            this.props.navigate(`/signup`);
            return;
        }

        let response = await ConnectionAPI.addFavorite({ user_id: user.id, id: id });
        if (!this.state.car.isFavorite) {
            //this means it is going to be favorite (the user clicked on the favorite button when the car was not favorite)
            pixelUserFavorite(user.name, user.email, id);
        }
        /* Set State of Favorite */
        this.setState({ car: { ...this.state.car, isFavorite: !this.state.car.isFavorite } });
    }

    renderListItem = ({ key, value, mobile, icon = null, tag = false }) => {
        return (
            <div className='car-page-item'>
                <div className='inline items-center'>
                    {icon ? 
                    <img src={icon} className='icon !w-5 !h-5 !mr-5' />
                    :
                    <></>
                    }
                    <h5 className='text-left'>{key}</h5>
                    {
                        mobile ?
                            <h5 className={`inline right-text ${tag ? 'tag green' : ''}`}>{value}</h5>
                            :
                            <></>
                    }
                </div>
                {
                    mobile ?
                        <></>
                        :
                        <div className='right'>
                            <h5 className={`inline right-text ${tag ? 'tag green' : ''}`}>{value}</h5>
                        </div>
                }

            </div>
        )
    }

    renderListItemNoIcon = ({ key, value, border = true, bold = false } = {}) => {
        return (
            <div className={`grid grid-list-no-icon ${!border ? 'no-border' : ''}`}>
                <div className='left'>
                    <h5 className={`text-left ${bold ? 'strong-text' : 'grey'}`}>{key}</h5>
                </div>
                <div className='right'>
                    <h5 className={`text-right ${bold ? 'strong-text' : ''}`}>{value}</h5>
                </div>
            </div>
        )
    }

    renderListItemTick = ({ value, border = true } = {}) => {
        return (
            <div className={`tick-list ${!border ? 'no-border' : ''}`}>
                <Done className='icon inline' />
                <h5 className='text-left inline h-fit'>{value}</h5>
            </div>
        )
    }

    buyCarButton = () => {
        const { user } = this.props;
        if(Object.keys(user).length !== 0)
            this.props.navigate(`/buy-form?id=${this.state.car.id}`);
        else{
            //make user create account
            this.props.navigate(`/signup?fromCar=${this.state.car.id}`);
        }
        
    }

    renderBuyContainer = () => {
        const { t, user } = this.props;
        return (
            <div className='grid grid-car-buy'>
                <div className='left'>
                    <button onClick={this.addFavorite} className='box-container no-margin no-padding icon'>
                        {this.state.car.isFavorite ?
                            <FavoriteOutlined width={30} className='no-margin' />
                            : <FavoriteBorderOutlined width={30} className='no-margin' />
                        }
                    </button>
                </div>
                <div className='right'>
                        <Button disabled={user ? false : true} className='button'
                            onClick={() => this.buyCarButton()}>
                            <h5> {t('buy_39')} </h5>
                        </Button>
                </div>
            </div>
        )
    }

    renderContactButton = () => {
        const { t, user } = this.props;
        let { showContact } = this.state;
        return (
            <div className='center'>
                {showContact
                ?
                <h5 className='button text-white'><a href='tel:+351914419292'>+351 914 419 292</a></h5>
                :
                <Button className='button' onClick={() => {
                    this.setState({showContact: true});
                    pixelUserMobilePhone();
                }}>
                    <img src={phone} className='inline'/>
                    <h5 className='inline'> {t('buy_41')} </h5>
                </Button>
                }
            </div>
        )
    }

    
    render = () => {
        const { car } = this.state;
        if (!car.id) return (<div></div>);
        //        const totalPrice = parseInt(parseFloat(car.price) + parseFloat(car.price) * parseFloat(car.vat/100));
        const totalPrice = car.price;
        const { t, i18n } = this.props;

        const mobile = isMobile(window);

        const priceDiv = (
            <div className={`box-container buy-container ${mobile ? 'mt-5' : ''}`}>
                <div className='box-container header padding'>
                    <h5 className='grey center-text'>{t('buy_29')}</h5>
                    <h2 className='bold-text center-text'>{formatNumber(totalPrice)} €</h2>
                    {/* <h5 className='grey center-text'>{formatNumber(car.priceWithoutVat)} € {t('without_vat')}</h5> */}
                </div>
                <div className='padding'>
                    <div className='box-container no-margin'>
                        <h5 className='primary-text small-container'>{car.make} {car.model}</h5>
                        {this.renderListItemNoIcon({ border: false, key: t('buy_30'), value: formatNumber(totalPrice) + " €", bold: true})}
                        {this.renderListItemNoIcon({ border: false, key: t('buy_31'), value: formatNumber(car.basePrice) + " €" })}
                        {car.vatPrice != 0 ?
                        this.renderListItemNoIcon({ border: false, key: t('buy_32'), value: formatNumber(car.vatPrice) + " €" })
                            : <></>
                        }
                        {this.renderListItemNoIcon({ border: false, key: t('buy_33'), value: formatNumber(car.servicePrice) + " €" })}
                        {this.renderListItemNoIcon({ border: false, key: t('buy_34'), value: formatNumber(car.isv) + " €" })}
                        {this.renderListItemNoIcon({ border: false, key: t('buy_35'), value: formatNumber(car.registrationPrice) + " €" })}
                        {this.renderListItemNoIcon({ border: false, key: t('buy_36'), value: formatNumber(car.transportPrice) + " €" })}
                    </div>
                    <div className='padding no-margin'>
                        {/* {this.renderListItemNoIcon({border : false, key : "Warranty", value : "123 €"})} */}
                        {this.renderListItemNoIcon({ border: false, key: t('buy_37'), value: car.Country[i18n.language] })}
                    </div>
                    {/* <div className='footer box-container'>
                        <div className='grid'>
                            <div className='left'>
                                <h4 className='primary-text bold-text'>{t('buy_30')}</h4>
                            </div>
                            <div className='right'>
                                <h4 className='primary-text bold-text'>{formatNumber(totalPrice)} €</h4>
                            </div>
                        </div>
                        <div className='grid small-container' style={{ marginBottom: 0 }}>
                            <div className='left'>
                                <h5 className='primary-text'>{t('buy_38')}</h5>
                            </div>
                            <div className='right'>
                                <h5 className='primary-text'>{formatNumber(car.priceWithoutVat)} €</h5>
                            </div>
                        </div>
                    </div> */}
                    <div className='small-container'></div>
                    {this.renderBuyContainer()}
                </div>
            </div>
        );
        return (
            <div className='car-page'>
                <Header />
                <div className='container'>
                    <div className='grid-car-page'>
                        <div className='left'>
                            <h2>{car.make} {car.model}</h2>
                            <h6 className='tag inline'>{car.version}</h6>
                            <h6 className='tag inline'>{car.vehicleType}</h6>
                        </div>
                        <div className='right'>
                            <h2 className='right-text'>{formatNumber(totalPrice)} €</h2>
                            {/* <div className='inline no-margin'>
                                <h5>{formatNumber(car.priceWithoutVat)} €</h5>
                                <h5 className='grey ml-2'> {t('without_vat')} </h5>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='container' style={{ paddingTop: 0 }}>
                    <div className={`${mobile ? 'block' : 'grid-car-page'}`} >
                        <div className='left'>
                            <div className='car-image'>
                                <ImageDisplayer imageUrls={car.photos} showArrows={true} showStatus={false} showThumbs={true} showIndicators={true} />
                            </div>
                            <div>
                                <h5>{t('buy_40') + " " + car.id}</h5>
                            </div>
                        </div>
                        <div className='right'>
                            {/* <div className='box-container small-container'>
                            {this.renderListItem({key : t('warranty'), value : car.guarantee, mobile: mobile, tag: true})}
                            </div> */}
                            <div className='box-container'>
                                {this.renderListItem({ key: t('buy_37'), value: car.Country[i18n.language], mobile: mobile, icon: locationIcon })}
                            </div>
                            <div className='box-container small-container'>
                                {this.renderListItem({ key: t('year'), value: car.year, mobile: mobile, icon: registrationDateIcon })}
                                {this.renderListItem({ key: t('mileage'), value: formatNumber(car.mileage) + " km", mobile: mobile, icon: mileageIcon })}
                                {this.renderListItem({ key: t('fuel'), value: car.CarFuel[i18n.language], mobile: mobile, icon: fuelIcon })}
                                {this.renderListItem({ key: t('transmission'), value: car.CarTransmission[i18n.language], mobile: mobile, icon: transmissionIcon })}
                                {this.renderListItem({ key: t('power'), value: car.power + " " + t('hp'), mobile: mobile, icon: powerIcon })}
                                {
                                    car.vatPrice != 0 ?
                                    this.renderListItem({ key: t('vat'), value: (car.vat * 100) + " %", mobile: mobile })
                                :
                                <></>
                                }
                            </div>
                            {this.renderBuyContainer()}
                            {this.renderContactButton()}
                        </div>
                    </div>
                </div>
                <div className='box-background section'>
                    <div className={`container ${mobile ? 'block' : 'grid-details'}`}>
                        {mobile ? priceDiv : <></>}
                        <div>
                            <h2 className='lext-text small-container'>{t('details')}</h2>
                            <div className={`${mobile ? 'block' : 'grid-50'}`}>
                                <div className={`box-container box-container-max ${mobile ? 'mb-5' : 'no-margin'}`}>
                                    <h4 className='bold-text'>{t('vehicle')}</h4>
                                    <div className='small-container'></div>
                                    {this.renderListItemNoIcon({ key: t('make'), value: car.make })}
                                    {this.renderListItemNoIcon({ key: t('model'), value: car.model })}
                                    {this.renderListItemNoIcon({ key: t('version'), value: car.version })}
                                    {this.renderListItemNoIcon({ key: t('vehicle_type'), value: 
                                        car.vatPrice == 0 ?
                                        t('buy_43')
                                        :
                                        t('buy_42')
                                        })}
                                    {this.renderListItemNoIcon({ key: t('body_type'), value: car.bodyType })}
                                    {this.renderListItemNoIcon({ key: t('color'), value: car.color })}
                                    {this.renderListItemNoIcon({ key: t('doors'), value: car.doors })}
                                    {this.renderListItemNoIcon({ key: t('seats'), value: car.seats })}
                                </div>
                                <div className='box-container no-margin'>
                                    <h4 className='bold-text'>{t('engine')}</h4>
                                    <div className='small-container'></div>
                                    {this.renderListItemNoIcon({ key: t('fuel'), value: car.CarFuel[i18n.language] })}
                                    {this.renderListItemNoIcon({ key: t('transmission'), value: car.CarTransmission[i18n.language] })}
                                    {this.renderListItemNoIcon({ key: t('power'), value: car.power + " kW" })}
                                    {this.renderListItemNoIcon({ key: t('cylinder'), value: car.cylinder })}
                                    {this.renderListItemNoIcon({ key: t('co2_emissions'), value: car.co2Emission })}
                                    {this.renderListItemNoIcon({ key: t('consumption'), value: car.consumption })}
                                </div>
                            </div>
                            <h2 className='lext-text small-container'>{t('features')}</h2>
                            <div className={`${mobile ? 'block' : 'grid-50'}`}>
                                <div className={`box-container ${mobile ? 'mb-5' : 'no-margin'}`}>
                                    <h4 className='bold-text'>{t('security_stab')}</h4>
                                    <div className='small-container'>
                                        {car.security.map((item) => this.renderListItemTick({ value: item[i18n.language + '_translation'], border: false }))}
                                    </div>
                                </div>
                                <div className='box-container no-margin'>
                                    <h4 className='bold-text'>{t('interior')}</h4>
                                    <div className='small-container'>
                                        <ul>
                                            {car.interior.map((item) => this.renderListItemTick({ value: item[i18n.language + '_translation'], border: false }))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='small-container'></div>
                            <div className={`${mobile ? 'block' : 'grid-50'}`}>
                                <div className={`box-container ${mobile ? 'mb-5' : 'no-margin'}`}>
                                    <h4 className='bold-text'>{t('multimedia')}</h4>
                                    <div className='small-container'>
                                        {car.multimedia.map((item) => this.renderListItemTick({ value: item[i18n.language + '_translation'], border: false }))}
                                    </div>
                                </div>
                                <div className='box-container no-margin'>
                                    <h4 className='bold-text'>{t('other')}</h4>
                                    <div className='small-container'>
                                        {car.other.map((item) => this.renderListItemTick({ value: item[i18n.language + '_translation'], border: false }))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {mobile ? <></> : priceDiv}
                    </div>
                </div>
                <Footer />
            </div>
        )
    }

}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
}

export default connect(mapStateToProps)(
    withTranslation()(withNavigate(CarPage))
);