/* Create FAQ Container with Questions and Answers */
import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import Button from '../../components/Button';
import { EnhancedEncryptionOutlined, FavoriteBorderOutlined, SupportOutlined } from '@mui/icons-material';


const ThreeItems = () => {

    return (
        <div className='section box-background'>
            <div className='container'>
                <h2 className='center small-container'> You don't risk anything by buying through Versicar </h2>
                <div className='section box-background'>
                    <div className='container grid-33'>
                        <div className='box-container center'>
                            <FavoriteBorderOutlined fontSize='large'/>
                            <h3>Money back Guarantee</h3> 
                            <h4> Text</h4>
                        </div>
                        <div className='box-container center'>
                            <EnhancedEncryptionOutlined fontSize='large'/>
                            <h3>Money back Guarantee</h3> 
                            <h4> Text</h4>
                        </div>
                        <div className='box-container center'>
                            <SupportOutlined fontSize='large'/>
                            <h3>Money back Guarantee</h3> 
                            <h4> Text</h4>
                        </div>
                    </div>
                </div>
                <div className='container primary section'> 
                    <h2 className='small-container center'>Check how Versicar Works</h2>
                    <h4 className='center'>Now all that's left is to choose your next car. We'll be delighted if it's from us.</h4>
                    <div className='small-container'></div>
                    <Link to='/buy' className='inline'>
                        <Button className='button primary fit center'> 
                            <h4>Choose your car</h4>
                        </Button>
                    </Link>
                </div>
            </div>
            
        </div>
    )
}

export default ThreeItems;