import { Component } from "react";
import { pixelUserSignup } from "../../../utils/pixel";
import { isValidEmail, isValidName, isValidPassword } from "../../lib/Helpers";
import UserAPI from "../../lib/User";
import Button from "../Button";
import GoogleSignup from "../GoogleSignup";
import InputText from "../InputText";
import Loading from "../Loading";
import { useTranslation, withTranslation } from "react-i18next";
import { withNavigate } from "../../../utils/navigator";
import { connect } from "react-redux";
import CacheSingleton from "../../lib/Cache";
import { PROMOTION_STORAGE_KEY } from "../../../utils/utils";


class ResgisterForm extends Component {
    constructor(props){
        super(props);
        const { keyItem, id } = this.props;

        this.state = {
            isLoading : false
        }
    }

    onChange = (key, value) => {
        this.setState({[key] : value});
    }

    isReadyToSubmitRegister = () => {
        return (
            isValidName(this.state['name'])
            && isValidEmail(this.state['email'])
            && isValidPassword(this.state['password'])
        )
    }

    __goToApp = async () => {
        this.props.navigate(`/`);
    }

    register = async () => {
        try{
            this.setState({ isLoading: true });
            
            if(this.props.promotion){
                //if user registered with promotion there is no need to show it again
                CacheSingleton.save(PROMOTION_STORAGE_KEY, false);
            }

            let res = await UserAPI.register({
                name : this.state.name,
                password : this.state.password,
                email : this.state.email,
                promotion: this.props.promotion
            })

            if(res.status != 200){ throw new Error(res.message)}
            //await GoogleAnalyticsSingleton.register({id : generateRandomString(5)});
 
            pixelUserSignup(this.state.name,this.state.email);

            if(this.props.goToApp)
                 await this.props.goToApp();
            else
                this.__goToApp();
        }catch(err){
            //Handle Err with UI
            console.log(err);
            this.setState({ isLoading: true });
            
            //this.setState({error : err, isLoading : false})
        }
    }

    render = () => {
        const { t } = this.props;
        const isDisabled = this.state.isLoading || !this.isReadyToSubmitRegister();
        return (
            <>
                <InputText
                    onChange={this.onChange} 
                    type={'name'}
                    keyItem='name'
                    form={this.state}
                    isValid={isValidName(this.state['name'])}
                    error={t('signup_1')}
                    title={t('name')} placeholder={t('signup_2')}
                />
                <InputText 
                    onChange={this.onChange} 
                    form={this.state}
                    keyItem='email'
                    isValid={isValidEmail(this.state['email'])}
                    error={t('signup_3')}
                    title={t('email')} placeholder={t('signup_4')} type={'email'}
                />
                <InputText 
                    onChange={this.onChange} 
                    form={this.state}
                    keyItem='password'
                    isValid={isValidPassword(this.state['password'])}
                    error={t('signup_5')}
                    title={"Password"} placeholder={t('signup_6')} type={'password'}
                />
                <Button disabled={isDisabled} 
                    className={`button ${isDisabled ? "btn-disabled" : ""}`} 
                    style={{marginTop : 10, opacity : this.state.isLoading ? 0.7 : 1}} 
                    onClick={this.register}>
                    {this.state.isLoading ? <Loading/> : 
                    <h4>
                        {t('signup_7')}
                    </h4>}
                </Button>
                
                <GoogleSignup goToApp={() => this.props.goToApp ? this.props.goToApp() : this.__goToApp()}
                        promotion={this.props.promotion}/>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        user : state.user
    };
}

export default connect(mapStateToProps)(withTranslation()(withNavigate(ResgisterForm)));