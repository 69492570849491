
import React, { Component, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import './index.scss'
import ContainerDropDown from '../Container';
import { isMobile } from '../../../../utils/utils';

class SelectDropDown extends Component{
    constructor(props){
        super(props);
        this.state = {
            currentList: this.props.list,
            originalList: this.props.list,
        }
    }
	
	handleChange = async (name) => {
        const { keyItem, parentField, options={} } = this.props;
		this.setState({item : name});
        this.changeDropDownStatus();
		await this.props.onChange(keyItem, name, parentField, options);   
	};
	
	emptyDropDown = () => {
		this.props.emptyDropDown(this.props.keyItem);
	}
    
    changeDropDownStatus = () => {
        this.container.changeDropDownStatus();
    }

    onSearchChange = (search) => {
        let { originalList } = this.state;
        let aux = []
        let isInList = false;
        if(search.length == 0)
            aux = this.props.list;
        else{
            search = search.toLowerCase();
            for(let i = 0; i < originalList.length; i++){
                if((originalList[i] + "").toLowerCase().startsWith(search)){
                    aux.push(originalList[i]);

                    if(originalList[i] == search){
                        isInList = true;
                    }
                }
            }  
        }
        
        //also check if the searched version already is in the list.
        //if it is, dont add it
        if(this.props.allowNewOption && search != '' && !isInList){
            aux.unshift(search);
        }
        this.setState({currentList: aux})
    }
    
    render = () => {
        const { title, list, form, keyItem, id, placeholder, parentField
            , removeX, isSearch, isRequired, noMargin, inline} = this.props;
        let { item, currentList, originalList } = this.state;


        if(form[parentField] != '' || form[parentField].length > 0){
            if(parentField)
                item = form[parentField][keyItem];
            else
                item = form[keyItem];
        }
        if(!currentList.includes(item)){
            item = '';
        }

        if(originalList.length != list.length){
            this.state.originalList = list;
            this.state.currentList = list;

            //check if the item is in the list
            if(!currentList.includes(item)){
                this.state.item = '';
            }
            currentList = this.state.currentList;
            item = this.state.item;
        }

        const mobile = isMobile(window);

        return (
            <div className={`box-container no-border no-padding !bg-transparent ${inline ? 'inline' : ''} ${inline && !mobile ? 'justify-between' : ''}`} style={{marginTop : noMargin ? 0 : 16}}>
                {
                    title ?
                    <span className='inline'>
                        <h5 className={'title bold-text'}>{title}</h5>
                        {isRequired ? <h5 className='!text-red-600 !pt-0 !pb-0 !pl-1 !pr-0'>*</h5> : null}
                    </span>
                    :
                    <div></div>
                }
                
                <div className={`${inline && !mobile ? 'mr-50' : ''}`}>
                    <ContainerDropDown    
                        id={id}
                        parentField={parentField}
                        placeholder={placeholder}
                        emptyDropDown={this.emptyDropDown} 
                        itemSelected={item}
                        keyItem={keyItem}
                        removeX={removeX}
                        form={form} 
                        title={title}
                        isSearch={isSearch}
                        onSearchChange={this.onSearchChange}
                        ref={(child) => this.container = child}
                        >
                        <div>
                            {currentList.map( (item, index) => {
                                return (
                                    <h5 onClick={async (e) => await this.handleChange(item,e)} className='itemlistselect' key={index}> 
                                    {item} 
                                    </h5>
                                )
                            })}
                        </div>
                    </ContainerDropDown>
                </div>

            </div>
        )
    }
}

export default SelectDropDown;
