import './index.scss';
import '../index.scss';
import { Component, useDebugValue, useEffect } from 'react';
import { connect } from 'react-redux';
import Loading from '../../../components/Loading';
import { MetaTags } from 'react-meta-tags';
import Select from '../../../components/DropDown/Select';
import DualOptionSelector from '../../../components/DualOptionSelector';
import MultiOptionSelector from '../../../components/MultiOptionSelector';
import Button from '../../../components/Button';
import InputText from '../../../components/InputText';
import UploadArea from '../../../components/UploadArea';
import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';
import ConnectionAPI from '../../../api';
import { billing, delivery, deliveryMethods } from '../../../../forms/buy'; 
import allFiltersMock from '../../../../filters/buy';
import UrlNavigatorSingleton from '../../../lib/UrlNavigator';
import Image from '../../../components/Image';
import Header from '../../../components/Header';
import { checkMarkSolid, hamburgerMenu } from '../../../../assets/images';
import { renderInput,renderItemList,renderDualOptionItem,renderMultiOptionItem,renderMultiSelection } from '../../../lib/Helpers';
import { withTranslation } from 'react-i18next';
import { pixelUserMobilePhone } from '../../../../utils/pixel';
import ImageDisplayer from '../../../components/ImageDisplayer';
import { isMobile } from '../../../../utils/utils';
import Footer from '../../../components/Footer';

const URL_CAR_STRIPE = process.env.REACT_APP_STRIPE_URL;

const allFilters = allFiltersMock

const form = {
    billing : billing,
    //delivery : delivery,
    car : null,
}
const price = 99;

class BuyFormPage extends Component{
    constructor(props){super(props);this.state = {loading : false, page : 1, form : form, isMenuOpen : false}}
   
    componentDidMount(){
        this.getData();
        const mobile = isMobile(window);
        if(!mobile)
            this.showMenu();        
    }

    showMenu = () => {
        this.setState(prevState => ({
            isMenuOpen: !prevState.isMenuOpen
        }));
    }
    
    getData = async () => {
        /* Get Car Id from Query Params */
        const { id } = UrlNavigatorSingleton.getFields(['id']);
        /* Get Car from API */
        let response = await ConnectionAPI.getCar({id});
        let { car } = response.message;
        /* Set Car in State */
        this.setState({car, form : {...this.state.form, car : car.id}});
    }
    
    submit = async () => {
        let res = await ConnectionAPI.createOrder({form : this.state.form});
        /* If status error show error message */
        if(res.status != 200){
            alert('error');
            return;
        }else{
            /* If success redirect to Stripe */
            setInterval(() => 
                UrlNavigatorSingleton.__toPage(`${URL_CAR_STRIPE}?prefilled_email=${this.props.user.email}`)
                //window.location.href = URL_CAR_STRIPE
            ,3000);
        }
        
        /* If error show error message */
        /* TO DO */
    }

    emptyDropDown = () => {
        console.log('emptyDropDown')
    }
    
    onChange = (key, value, parentField, options={variable : false, array : false}) => {
        if(key == 'deliveryMethod'){
            const { t } = this.props;
            value = deliveryMethods(t).get(value);
        }
        if(options.array){
            if(options.variable){
                const array = this.state.form[parentField][key];
                if(array.includes(value)){
                    const index = array.indexOf(value);
                    array.splice(index, 1);
                }else{
                    array.push(value);
                }
                this.setState({form : {...this.state.form, [parentField] : {...this.state.form[parentField], [key] : array}}});
            }else{
                this.setState({form : {...this.state.form, [parentField] : {...this.state.form[parentField], [key] : [value]}}});
            }
        }else{
            this.setState({form : {...this.state.form, [parentField] : {...this.state.form[parentField], [key] : value}}});
        }
    }
    
    changePage = (page) => {
        this.setState({page})
    }
    
    renderListItem = (item, index) => {
        const { page } = this.state;
        const isActive = page === (index+1);

        let isDisabled = index+1 > page;
        let isCompleted = index+1 < page;
        return (
            <button disabled={isDisabled} key={index} className={`list-item ${isActive ? 'active' : ''} ${isDisabled ? 'btn-disabled' : ''}`} 
            onClick={() => this.changePage(index+1)}>
                {isCompleted ? <img src={checkMarkSolid}/>: <h5 className='number inline'>{index+1}</h5>}
                <h5 className='inline bold-text'>
                    {item}
                </h5>
            </button>
        )
    }
    
    renderUpload = ({title, keyItem, parentField}) => {
        return (
            <div className='small-container'>
                <UploadArea form={this.state.form} title={title} onChange={this.onChange} parentField={parentField} keyItem={keyItem} />
            </div>
        )
    }
    
    renderPageHeader = ({page, title}) => {
        const { t } = this.props;
        return (<>
            <h5 className='primary-text'>{t('step')} {page}</h5>
            <h2>{title}</h2>
        </>)
    
    }
    
    renderPageContinue = ({page, disabled = false}) => {
        const { t } = this.props;
        return (
            <Button disabled={disabled} 
             className={`button small-container ${disabled ? 'btn-disabled' : ''}`} onClick={() => this.changePage(page)}>
                <h5>{t('continue')}</h5>
            </Button>
        )
    }
    
    renderBuyButton = () => {
        const { t } = this.props;
        return (
            <>
                <Button className='button small-container' onClick={this.submit}>
                    <h5>{t('buy_27')}</h5>
                </Button>
                <h6 className='grey center'>{t('buy_28')}</h6> 
            </>
        )
    }
    
    renderNumberList = ({number, title, description}) => {
        return (
            <div className='box'>
                <div className='inline'>
                    <h5 className='bold-text inline number-blue'>{number}</h5>
                    <div className='small-container'/>
                    <h4 className='bold-text' style={{marginLeft : 12}}>{title}<h5>{description}</h5></h4>
                </div>
            </div>
        )
    }
    
    renderPageHeader = ({page, title}) => {
        const { t } = this.props;
        return (<>
            <h5 className='primary-text'>{t('step')} {page}</h5>
            <h2>{title}</h2>
        </>)
    
    }
    
    checkRequiredField = (parent,keys) => {
        let canContinue = true;
        for(let j = 0; j < keys.length && canContinue; j++){
            let key = keys[j];
            if(this.state.form[parent][key] == null || this.state.form[parent][key] == undefined
                    || this.state.form[parent][key] == ''){
                    canContinue = false;
                    break;
                }
        }
        return canContinue;
    }

    renderCarConditionCheck = () => {
        const { t } = this.props;
        if(!this.state.form.billing['clientType']){
            this.state.form.billing['clientType'] = t('customer');
        }

        const mobile = isMobile(window);
        const isClient = this.state.form.billing['clientType'] == t('customer');

        let keys = ["clientType","country", "firstName", "lastName", "phoneNumber","email", "city", "street", "postalCode","houseNumber"]
        if(!isClient){
            keys.push('companyName');
            keys.push('vat');
        }

        let parent = 'billing';
        let canContinue = this.checkRequiredField(parent,keys);

        return(
            <div className='form-content container'>
                {this.renderPageHeader({page : 1, title : t('buy_5')})}
                <div className='box-container small-container'>
                    <div className='small-container'/>
                    <h5> {t('buy_6')} </h5>
                    <div className='small-container'/>
                    <div className='box-background box-container'>
                        <h4 className='bold-text'>{t('buy_7')}</h4>
                        <div className={`${mobile ? '' : 'grid-50 grid'}`}>
                            <div>
                                {this.renderNumberList({number : 1, title : t('buy_8'), description : t('buy_9')})}
                                {this.renderNumberList({number : 2, title : t('buy_10'), description : t('buy_11')})}
                                {this.renderNumberList({number : 3, title : t('buy_12'), description : t('buy_13')})}                
                            </div>
                            <div className='box-container background-color'>
                                <div className='small-container'></div>
                                <h4 className='center-text'>{t('buy_14')}</h4>
                                <div className='small-container'></div>
                                <h2 className='center-text line-through opacity-30'>199€</h2>
                                <h2 className='center-text'>{price}€</h2>
                                <div className='green-container small-container box-container'>
                                    <CheckCircle className='inline green'/>
                                    <h5 className='inline'>{t('buy_15')}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='small-container'/>
                </div>
                <div className='container'>
                    <h3>{t('buy_24')}</h3>
                    <div>
                    {renderDualOptionItem({isRequired:true,onChange:this.onChange,
                                form: this.state.form,
                                options : [t('customer'),t('company')], 
                                keyItem : "clientType", parentField : "billing"})}
                    </div>
                    <div>
                        {renderItemList({isRequired:true,form: this.state.form,emptyDropDown:this.emptyDropDown,onChange:this.onChange
                        ,allFilters:allFilters,title : t('country')
                            , placeholder : t('select'), keyItem : "country", parentField : 'billing'})}
                    </div>
                    <div className='grid-50'>
                        {renderInput({isRequired:true,form: this.state.form,onChange:this.onChange,title : t('first_name'), placeholder : t('first_name')
                            , keyItem : "firstName", parentField : "billing"})}
                        {renderInput({isRequired:true,form: this.state.form,onChange:this.onChange,title : t('surname'), placeholder : t('surname')
                            , keyItem : "lastName", parentField : "billing"})}
                    </div>
                    <div className={`${isClient ? '' : 'grid-50'}`}>
                        {
                            isClient ? <></> : (
                            renderInput({isRequired:true,form: this.state.form,onChange:this.onChange,title : t('buy_25'), placeholder :t('company_name')
                                , keyItem : "companyName", parentField : "billing"}))
                        }
                        {renderInput({type:"number",form: this.state.form,onChange:this.onChange,
                            isRequired: !isClient,
                            title : isClient ? t('buy_26') : t('buy_26_1') 
                            , placeholder : isClient ? t('buy_26') : t('buy_26_1'), keyItem : "vat", parentField : "billing"})}
                    </div>
                    <div className='grid-50'>
                        {renderInput({type:"tel",isRequired:true,form: this.state.form,onChange:this.onChange,title : t('phone_number'), placeholder : t('phone_number'),
                             keyItem : "phoneNumber", parentField : "billing"})}
                        {renderInput({type:"email",isRequired:true,form: this.state.form,onChange:this.onChange,title : t('email'), placeholder : t('email'),
                             keyItem : "email", parentField : "billing", parentField : "billing"})}
                    </div>
                    <div className={`${mobile ? '' : 'grid-50'}`}>
                        <div className='grid-50'>
                            {renderInput({isRequired:true,form: this.state.form,onChange:this.onChange,title : t('city'), placeholder : t('city'),
                                keyItem : "city", parentField : "billing"})}
                            {renderInput({isRequired:true,form: this.state.form,onChange:this.onChange,title : t('street'), placeholder : t('street'),
                                 keyItem : "street", parentField : "billing"})}
                        </div>
                        <div className='grid-50'>
                            {renderInput({isRequired:true,form: this.state.form,onChange:this.onChange,title : t('postal_code'), placeholder : t('postal_code'),
                                 keyItem : "postalCode", parentField : "billing"})}
                            {renderInput({isRequired:true,form: this.state.form,onChange:this.onChange,title : t('house_number'), placeholder : t('house_number'),
                                 keyItem : "houseNumber", parentField : "billing"})}
                        </div>
                    </div>
                </div>
                {this.renderPageContinue({page : 2, disabled: !canContinue})}
            </div>
        )
    }
    
    renderDelivery = () => {
        let keys = ["country", "firstName", "lastName", "phoneNumber","email", "city", "postalCode","deliveryAddress","houseNumber"]
        let parent = 'delivery';
        let canContinue = this.checkRequiredField(parent,keys);
        const { t } = this.props;

        const mobile = isMobile(window);
        return (
            <div className='form-content container'>
                {this.renderPageHeader({page : 2, title : t('buy_16')})}
                <div className={`${mobile ? '' : 'grid-50'}`}>
                    <div className='small-container'>
                    <h4 className='small-container bold-text'>{t('buy_17')}</h4>                        
                        <div className='box-container small-container'>
                            {renderItemList({isRequired:true,form: this.state.form,emptyDropDown:this.emptyDropDown,onChange:this.onChange,
                                allFilters:allFilters
                                ,title : t('country'), placeholder : t('select'), keyItem : "country", parentField : 'delivery'})}  
                            {renderDualOptionItem({isRequired:true,onChange:this.onChange,title : t('delivery_method'), form: this.state.form,
                                options : Array.from(deliveryMethods(t).keys()), 
                                keyItem : "deliveryMethod", parentField : "delivery"})}
                            {renderInput({isRequired:true,form: this.state.form,onChange:this.onChange,title : t('delivery_address'), placeholder :  t('delivery_address'), keyItem : "deliveryAddress", parentField : "delivery"})}
                            <div className={`${mobile ? '' : 'grid-50'}`}>
                                {renderInput({isRequired:true,form: this.state.form,onChange:this.onChange,title : t('city'), placeholder : t('city'), keyItem : "city", parentField : "delivery"})}
                                {renderInput({isRequired:true,form: this.state.form,onChange:this.onChange,title : t('postal_code'), placeholder : t('postal_code'), keyItem : "postalCode", parentField : "delivery"})}
                            </div>
                            {renderInput({isRequired:true,form: this.state.form,onChange:this.onChange,title : t('house_number'), placeholder : t('house_number'),
                                 keyItem : "houseNumber", parentField : "delivery"})}
                        </div>
                    </div>
                    <div className='small-container'>
                        {/* Delivery Data */}
                        <h4 className='small-container bold-text'>{t('buy_18')}</h4>                        
                        <div className='box-container small-container'>
                            {renderInput({isRequired:true,form: this.state.form,onChange:this.onChange,title : t('first_name'), placeholder : t('first_name'), keyItem : "firstName", parentField : "delivery"})}
                            {renderInput({isRequired:true,form: this.state.form,onChange:this.onChange,title : t('surname'), placeholder : t('surname'), keyItem : "lastName", parentField : "delivery"})}
                            {renderInput({isRequired:true,form: this.state.form,onChange:this.onChange,title : t('phone_number'),type:"tel", placeholder : t('phone_number'), keyItem : "phoneNumber", parentField : "delivery"})}
                            {renderInput({isRequired:true,form: this.state.form,onChange:this.onChange,title : t('email'),type:"email", placeholder : t('email'), keyItem : "email", parentField : "delivery"})}
                        </div>
                    </div>
                
                </div>
                {this.renderPageContinue({page : 3, disabled: !canContinue})}
            </div>
        )
    }
    
    renderPayment = () => {
        const { form } = this.state;
        const { t } = this.props;

        const mobile = isMobile(window);
        return (
            <div className='form-content container'>
                {this.renderPageHeader({page : 3, title : t('buy_19')})}
                <div className='small-container'>
                    <div className='box-container small-container box-background container'>
                    <h4 className='small-container primary-text bold-text'>{t('buy_20')}</h4>
                    <div className='small-container'/>
                    <div className={`${mobile ? '' : ''} small-container`}>
                        <div className='small-container'>                            
                            <div className='grid-50'>
                                <h5>{t('first_name')} <h4 className='bold-text'>{form.billing.firstName}</h4></h5>
                                <h5>{t('surname')} <h4 className='bold-text'>{form.billing.lastName}</h4></h5>
                            </div>
                            {form.billing.companyName && form.billing.vat &&
                            <div className='grid-50 small-container'>   
                                <h5>{t('company')} <h4 className='bold-text'>{form.billing.companyName}</h4></h5>
                                <h5>{t('vat')} <h4 className='bold-text'>{form.billing.vatNumber}</h4></h5> 
                            </div>
                            }
                            <div className='grid-50 small-container'>
                                <h5>{t('phone_number')} <h4 className='bold-text'>{form.billing.phoneNumber}</h4></h5>
                                <h5>{t('email')} <h4 className='bold-text'>{form.billing.email}</h4></h5>
                            </div>
                            <div className='grid-50 small-container'>
                                <h5>{t('city')} <h4 className='bold-text'>{form.billing.city}</h4></h5>
                                <h5>{t('postal_code')} <h4 className='bold-text'>{form.billing.postalCode}</h4></h5>
                            </div>
                        </div>
                        {/* <div className='box-container'>
                            <h5 className='small-container'>{t('delivery_method')} <h4 className='bold-text'>{form.delivery.deliveryMethod}</h4></h5>
                            <h5 className='small-container'>{t('delivery_address')} <h4 className='bold-text'>{form.delivery.deliveryAddress}</h4></h5>    
                            <div className='grid-50'>
                                <h5>{t('city')} <h4 className='bold-text'>{form.delivery.city}</h4></h5>
                                <h5>{t('postal_code')} <h4 className='bold-text'>{form.delivery.postalCode}</h4></h5>
                            </div>
                            <h5>{t('buy_21')} </h5>
                        </div> */}
                    </div>
                    <h5 className='small-container'>{t('buy_14')} 
                        <h2 className='bold-text'>{price}€</h2>
                    </h5>
                    <div className='small-container'/>
                        <div className='grid-50'>
                            <div className='small-container inline'>
                                <CheckCircleOutline className='green icon' style={{marginRight : 6}}/>
                                <h5 className='bold-text' style={{marginTop : 3}}>{t('buy_22')}</h5>
                            </div>
                            <div className='small-container inline'>
                                <CheckCircleOutline className='green icon' style={{marginRight : 6}}/>
                                <h5 className='bold-text' style={{marginTop : 3}}>{t('buy_23')}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderBuyButton()}
            </div>
        )
    }
  
    renderPages = () => {
        //
        switch(this.state.page){
            case 1:
                return this.renderCarConditionCheck();
            // case 2:
            //     return this.renderDelivery();
            case 2:
                return this.renderPayment();
            default:
                return null;
        }
    }

    render = () => {
        const { loading, car } = this.state;
        const { t } = this.props;
        const list = [t('buy_5'),
            //t('buy_16'),
            t('buy_19')];
        const mobile = isMobile(window);
        const isMenuOpen = true;//this.state.isMenuOpen;
        return (
            <div className='form-page'>  
                <>{ (loading) ? <Loading/> : null}</>
                <MetaTags>
                    <title> Versicar </title>
                    <meta name="description" content="Buy your best car" />
                    <meta property="og:title" content="Versicar" />
                </MetaTags>
                <Header/>
                <div className={`${mobile ? '' : 'grid-form grid'}`}>
                    <div className={`${mobile ? '' : 'left padding'}`}>
                        <div className='box-container box-background no-border container-form-left'>
                            {car ? 
                            <>
                                <div className='inline'>
                                    {
                                    mobile ?
                                        <img src={hamburgerMenu} height={16} width={16} onClick={this.showMenu} />
                                            :
                                            <></>
                                    }
                                    <h5 className='grey'>
                                        {t('buy_3')} <h5 className='inline bold-text'>{car.make} {car.model}</h5>
                                    </h5>
                                </div>
                                {isMenuOpen ? (
                                        <>
                                        {list.map((item, index) => (
                                            this.renderListItem(item, index)
                                        ))}
                                        <div className='small-container' />
                                        <div className='bottom-page-container'>
                                            <div className='grid'>
                                            <ImageDisplayer
                                                imageUrls={[car.photos[0]]}
                                                imageClasses='image inline'
                                            />
                                            <div>
                                                <h5 className='bold-text'>
                                                {car.make} {car.model}
                                                </h5>
                                                <div className='grid-50'>
                                                <h5>{car.mileage} km</h5>
                                                <h5>{car.year}</h5>
                                                </div>
                                            </div>
                                            </div>
                                            <div className='small-container' />
                                            <h5 className='center bold-text'>{t('buy_4')}</h5>
                                            <Button onClick={() => pixelUserMobilePhone()}>
                                                <h5 className='tag center no-margin'><a href='tel:+351914419292'>+351 914 419 292</a></h5>
                                            </Button>
                                        </div>
                                        </>
                                    ) : (
                                        <></>
                                    )
                                }
                            </>
                            : null
                            }
                        </div>
                    </div>
                    <div className={`${mobile ? '' : 'right padding'}`}>
                        {this.renderPages()}
                    </div>
                </div>
            </div>
            )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
}
  
export default connect(mapStateToProps)(withTranslation()(BuyFormPage));
