import { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { MetaTags } from 'react-meta-tags';

class MyCarsForSalePage extends Component{
    constructor(props){
        super(props);
        this.state = {
            cars : []
        }
    }
    
    render = () => {
        const { user } = this.props;
        if(!user.id){return null};
        return (
            <div className='page'>
                <MetaTags>
                    <title>My Cars for Sale</title>
                    <meta name="description" content="Manage your Account" />
                    <meta property="og:title" content="Account" />
                </MetaTags>
                <div className='container'>
                  
                </div>       
            </div>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }
  
  export default connect(mapStateToProps)(MyCarsForSalePage);
