import React,{ Component } from 'react';
import { connect } from 'react-redux';
import './index.scss'
import variables from '../../../../_export.scss';
import { IconButton } from '@material-ui/core';
import { CloseOutlined, KeyboardArrowDown } from '@mui/icons-material';
import Button from '../../Button';
import { MOBILE_WIDTH } from '../../..';
import { withTranslation } from 'react-i18next';

class ContainerDropDown extends Component{
    constructor(props){
        super(props);
        const { keyItem, id } = this.props;

        this.state = {
            isSelected : false,
            canOpenMenu: true,
        }
        this.ref = React.createRef();
        this.buttonRef = React.createRef();
    }

	handleChange = (event) => {
		this.setState({item : event.target.value});
	};
    
    changeDropDownStatus = () => {
        this.setState({isSelected : !this.state.isSelected})
    }
    
    openDropDownStatus = () => {
        const{ id,keyItem } = this.props;

        if(this.state.canOpenMenu){
            this.setState({isSelected : true});
            document.getElementById(`modal-${this.props.keyItem}-${id}`).classList.replace('hideModal', "showModal");


            let comp = document.getElementById("input" + keyItem);
            if(comp){
                comp.focus();
            }
        }
        this.setState({canOpenMenu : true});
    }
    
    emptyDropDown = (event) => {
       this.props.emptyDropDown(this.props.keyItem);
       this.props.onSearchChange('');
       this.setState({isSelected : false});
       event.stopPropagation();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.onFocusLost);
        document.addEventListener("mouseup", this.onFocusGet);
    }
    
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onFocusLost);
        document.removeEventListener("mouseup", this.onFocusGet);
    }

    onFocusLost = (e) => {
        const { isSelected,dropDownId } = this.state;

        let isClosing = false;
        if (!this.ref.current.contains(e.target) && isSelected) {
            this.changeDropDownStatus();
            isClosing = true;
        }

        if (this.buttonRef.current.contains(e.target) && isClosing) {
            this.setState({ canOpenMenu: false });
        }
    };

    onFocusGet = (e) => {
        //the user cannot open the menu if the menu was opened and the user clicked again on the menu button
        if (!(this.buttonRef.current.contains(e.target) && !this.state.canOpenMenu))
            this.setState({ canOpenMenu: true });
    }

    onSearchChange = (event) => {
        this.props.onSearchChange(event.target.value);
    }

    render = () => {
		const { title, isSearch, form, keyItem, itemSelected, iconTitle, id, placeholder, parentField, removeX=false } = this.props;
        const { isSelected } = this.state;

        var filters;
        if(parentField){
            filters = form[parentField];
        }else{
            filters = form;
        }
        let isActive = (filters[keyItem] != '' || filters[keyItem].length > 0);
        if(removeX){
            isActive = false;
        }

        //if(keyItem == 'sort')
            //console.log(itemSelected);
        let isMobile = window.innerWidth < MOBILE_WIDTH;
        return (
			<div className='container-multiSelect modal-selector'>
                <div ref={this.buttonRef} className={`selected-button ${isActive && !isSelected ? 'activeButton' : null}`} onClick={this.openDropDownStatus}>
                    {itemSelected && !isSelected ? 
                        <h5 className='inline'>{itemSelected}</h5>
                    : (
                        isSearch && isSelected ? 
                           <input autoComplete='off' autoFocus={true} type='text' id={"input" + keyItem} onChange={this.onSearchChange} 
                                    className="absolute itemInput"/>
                         
                        :
                        (
                            isSelected ?
                                <h5 className='inline'>{itemSelected}</h5>
                            :
                            <h5 className='inline placeholder'>
                                {isMobile ? <div className='icon-multiSelect grey'>{iconTitle}</div> : placeholder}
                            </h5>
                        )
                    )
                    }
                    {
                        isSearch && isSelected ?
                        <>
                        </>
                        :
                        (
                        isActive && !removeX ? 
                            <IconButton style={{margin : 0, padding : 0, height : 25}} onClick={this.emptyDropDown}>
                                <CloseOutlined style={{color : variables.backgroundColor, marginLeft : 0, marginTop : 10}}/>
                            </IconButton>
                        :
                        <div className='inline no-margin'>
                            {/* {isMobile ? null :  */}
                                <KeyboardArrowDown style={{color : variables.textColor}}/>
                            {/* } */}
                        </div>
                        )
                    }
				</div>
                <div ref={this.ref} 
                    id={`modal-${keyItem}-${id}`} className={`div-multiSelectBody modal ${isSelected && isSearch ? '!mt-6' : ''} 
                    ${isSelected ? 'showModal' : 'hideModal'}`} >
                    <div className='children-container'>
                        {this.props.children}
                    </div>
                    <div className='clear-box-container'>
                        <Button onClick={this.changeDropDownStatus} className='btn close-button thin-btn button'>
                            <h5>Fechar</h5>
                        </Button>
                    </div>
                </div>
		  	</div>
        )
    }
    
}

export default ContainerDropDown;
