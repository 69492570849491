import ReactPixel from 'react-facebook-pixel';



export const pixelUserSignup = (username, email) => {
    ReactPixel.track('UserSignup',
        {
            value: {name: username, email: email}
        }
      );
}

export const pixelUserFavorite = (username, email, carId) => {
    ReactPixel.track('UserFavorite',
        {
            value: {name: username, email: email, carId : carId}
        }
      );
}

export const pixelUserMobilePhone = () => {
    ReactPixel.track('UserMobilePhone');
}

export const pixelCarListPage = () => {
    ReactPixel.track('CarListView',
        {}
      );
}

export const pixelBuyPageFromFilter = () => {
    ReactPixel.track('CarListViewFromFilter',
        {}
      );
}

export const pixelBuyPageFromHeader = () => {
    ReactPixel.track('CarListViewFromHeader',
        {}
      );
}

export const pixelCarPageView = (email, carId) => {
    ReactPixel.track('CarPageView',
        {
            value: {email: email, carId : carId}
        }
      );
}