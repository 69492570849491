/* Create Brand Picker Modal */
/* Should be able to select a brand from a list of brands */
/* Should be able to search for a brand */
/* Should be able to create a new brand */

import { connect } from "react-redux";
import Modal from "..";
import "./index.scss";
import 'react-calendar/dist/Calendar.css';
import { withTranslation } from "react-i18next";
import { renderInput, renderItemList } from "../../../lib/Helpers";
import { Component } from "react";
import { filtersSearch, getFilterValues } from "../../../../filters/search";
import ContainedLoading from "../../ContainedLoading";
import { Button } from "@mui/material";
import APIConnection from "../../../api"

const formDefault = {
    'make': '',
    'model': '',
    'registrationFrom': '',
    'fuel': '',
    'mileageTo': '',
    'email': '',
    'phone_number': '',
    'other_requests': ''
}
class CarRequestModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            form: formDefault,
            allFilters: null,
        }
    }

    componentDidMount = async () =>  {
        const { t, i18n, user } = this.props;
        const filters = await filtersSearch(t,i18n.language,this.state.form.make);
        if(this.state.allFilters != null){
            filters.registrationTo = this.state.allFilters.registrationTo;
            filters.priceTo = this.state.allFilters.priceTo;
        }
        
        const aux = this.state.form;
        if(user.email)
            aux.email = user.email;
        if(user.phone_number)
            aux.phone_number = user.phone_number;

        this.setState({allFilters: filters, form: aux});
    }

    emptyDropDown = (subType) => {
        const aux = this.state.form;
        aux[subType] = '';
        this.setState({form: aux});
    };

    onChange = (key, value, parentField, options={variable : false, array : false}) => {
        this.setState(prevState => ({
            form: {
                ...this.state.form,
                [key]: value
            }
        }));
    }

    sendRequest = async () => {
        const { t, i18n } = this.props;
        const aux = this.state.form;

        //we only need to get the value of the fuel, so make after that the other values (make, model or more) are still normal
        const form = await getFilterValues(t,i18n.language,this.state.form);
        form.make = aux.make;
        form.model = aux.model;
        form.other_requests = aux.other_requests;
        let response = await APIConnection.createRequest({
            form
        });

        console.log(response);
        if(response.status !== 200){
            alert(t('request_4'));
        }else{
            window.location.reload();
        }
    }

    render = () => {
        const { user, t } = this.props;

        return (
            <Modal keyItem='NewUserPromotion' middle={true} onClose={() => this.props.onClose()}>
                <div className="flex flex-col items-center request-modal-container">
                    {
                        this.state.allFilters == null ?
                        <ContainedLoading/>
                        :
                        <div className="flex flex-col">
                            <div className="center">
                                <h2>
                                    {t('request_1')}
                                </h2>
                            </div>
                            {renderInput({title : t('make'), keyItem : 'make', placeholder : t('make'),
                                form:this.state.form,
                                onChange: this.onChange,
                                isRequired: true
                            })}
                            {renderInput({title : t('model'), keyItem : 'model', placeholder : t('model'),
                                form:this.state.form,
                                onChange: this.onChange,
                                isRequired: true
                            })}
                            {renderItemList({title : t('year'), keyItem : 'registrationFrom', placeholder : t('year'),
                                allFilters: this.state.allFilters,emptyDropDown: this.emptyDropDown,form:this.state.form,onChange: this.onChange
                                ,search: true,id: 3,allowNewOption:true,isRequired: true
                            })}
                            {renderItemList({title : t('fuel'), keyItem : 'fuel', placeholder : t('fuel'),
                                allFilters: this.state.allFilters,emptyDropDown: this.emptyDropDown,form:this.state.form,onChange: this.onChange,
                                id: 3,isRequired: true
                            })}
                            {renderInput({type:'number',title : t('request_3'), keyItem : 'mileageTo', placeholder : t('mileage'),
                                form:this.state.form,onChange: this.onChange,isRequired: true
                            })}
                            {renderInput({type:'email',title : t('email'), keyItem : 'email', placeholder : t('email'),
                                form:this.state.form,onChange: this.onChange,isRequired: true
                            })}
                            {renderInput({type:'tel',title : t('phone_number'), keyItem : 'phone_number', placeholder : t('phone_number'),
                                form:this.state.form,onChange: this.onChange,isRequired: true
                            })}

                            {renderInput({type:'text',title : t('other_requests'), keyItem : 'other_requests', placeholder : t('other_requests'),
                                form:this.state.form,onChange: this.onChange, textArea:true,cols:30
                            })}
                            <Button className='button fit ml-5' onClick={() => this.sendRequest()}>
                                <h6>{t('request_2')}</h6>
                            </Button>
                        </div>
                    }
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        user : state.user,
    };
}

export default connect(mapStateToProps)(withTranslation()(CarRequestModal));