import './index.scss';
import { useTranslation } from "react-i18next";
import Image from "../Image";
import { hero } from "../../../assets/images";
import { use } from 'i18next';
import { Button } from '@mui/material';
import { useState } from 'react';
import CarRequestModal from '../Modal/CarRequestModal';
import { scrollToTopPage } from '../../pages/Utils/utils';


const CantFindCard = (props) => {
    const [openModal, setOpenModal] = useState(false);
    const { t, i18n } = useTranslation();

    const openCarRequest = () => {
        setOpenModal(true);
    }

    return (
        <>
        {openModal
            ?
                <CarRequestModal onClose={() => setOpenModal(false)}/>
            :
            <></>
        }
        <div className='cant-find-container'>
            <div className='cant-find-background'></div>
            <Image src={hero} className={'cant-find-image'} alt='Imagem de legalização de carro importado' />
            <div className='center flex-col z-10 !justify-evenly'>
                <h2 className='text-white'>
                    {t('cant_find_1')}
                </h2>
                <Button className='button fit ml-5 !bg-white' onClick={() => openCarRequest()}>
                    <h6 className='!text-black'>{t('contact_us')}</h6>
                </Button>
            </div>
        </div>
        </>
    );
}

export default CantFindCard;