import './index.scss';
import '../index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../../components/Loading';
import { MetaTags } from 'react-meta-tags';
import Button from '../../../components/Button';
import UploadArea from '../../../components/UploadArea';
import allFiltersMock from '../../../../filters/sell';
import ConnectionAPI from '../../../api';
import { contact, photos, vehicle,formToDbCar } from '../../../../forms/sell';
import Header from '../../../components/Header';
import { renderInput,renderItemList,renderDualOptionItem,renderMultiOptionItem,renderMultiSelection } from '../../../lib/Helpers';
import { checkMarkSolid } from '../../../../assets/images';


const list = ['Specification', "Equipment", "Car Condition", "Contact Information", "Photos", "Car Valuation"];

const allFilters = allFiltersMock;

const form = {
    vehicle : vehicle,
    contact : contact,
    photos : photos
}

class SellFormPage extends Component{
    constructor(props){super(props);
    this.state = {loading : false, page : 1, form : form}}
    
    componentDidMount(){}    
    
    submit = async () => {
        await ConnectionAPI.registerCar({car : formToDbCar(this.state.form)});
    }

    emptyDropDown = () => {
        console.log('emptyDropDown')
    }
    
    onChange = (key, value, parentField, options={variable : false, array : false}) => {
        if(options.array){
            if(options.variable){
                const array = this.state.form[parentField][key];
                if(array.includes(value)){
                    const index = array.indexOf(value);
                    array.splice(index, 1);
                }else{
                    array.push(value);
                }
                this.setState({form : {...this.state.form, [parentField] : {...this.state.form[parentField], [key] : array}}});
            }else{
                this.setState({form : {...this.state.form, [parentField] : {...this.state.form[parentField], [key] : [value]}}});
            }
        }else{
            this.setState({form : {...this.state.form, [parentField] : {...this.state.form[parentField], [key] : value}}});
        }
    }
    
    changePage = (page) => {
        this.setState({page})
    }
    
    renderPageNumber = (item, index) => {
        const { page } = this.state;
        const isActive = page === (index+1);

        let isDisabled = index+1 > page;
        let isCompleted = index+1 < page;
        return (
            <button disabled={isDisabled} key={index} className={`list-item ${isActive ? 'active' : ''} ${isDisabled ? 'btn-disabled' : ''}`} 
            onClick={() => this.changePage(index+1)}>
                {isCompleted ? <img src={checkMarkSolid}/>: <h5 className='number inline'>{index+1}</h5>}
                <h5 className='inline bold-text'>
                    {item}
                </h5>
            </button>
        )
    }
    
    renderUpload = ({title, keyItem, parentField}) => {
        return (
            <div className='small-container'>
                <UploadArea form={this.state.form} title={title} onChange={this.onChange} parentField={parentField} keyItem={keyItem} />
            </div>
        )
    }
    
    renderPageHeader = ({page, title}) => {
        return (<>
            <h5 className='primary-text'>Step {page}</h5>
            <h2>{title}</h2>
        </>)
    
    }
    
    renderPageContinue = ({page, disabled = false}) => {
        return (
            <Button disabled={disabled} 
             className={`button small-container ${disabled ? 'btn-disabled' : ''}`} onClick={() => this.changePage(page)}>
                <h5>Continue</h5>
            </Button>
        )
    }

    renderPageSubmit = () => {
        return (
            <Button className='button small-container' onClick={() => this.submit()}>
                <h5>Submit</h5>
            </Button>
        )
    }

    checkRequiredField = (parent,keys) => {
        let canContinue = true;
        for(let j = 0; j < keys.length && canContinue; j++){
            let key = keys[j];
            if(this.state.form[parent][key] == null || this.state.form[parent][key] == undefined
                    || this.state.form[parent][key] == ''){
                    canContinue = false;
                    break;
                }
        }
        return canContinue;
    }
    
    renderSpecificationPage = () => {
        //check if it is possible to advance to the next page
        let keys = ["make", "model", "year", "version","transmission", "fuel", "vehicleType", "doors", "power","engine","mileage","co2Emission","color","interiorMaterial","vatDeductible", "country"]
        let parent = 'vehicle';
        let canContinue = this.checkRequiredField(parent,keys);
        return(
            <div className='form-content container'>
                {this.renderPageHeader({page : 1, title : "Vehicle Specification"})}
                <div className='small-container'></div>
                <div className='grid-50'>
                    {renderItemList({
                        isRequired:true,
                        title : "Make", placeholder : "Select ", keyItem : "make", parentField : 'vehicle',allFilters: allFilters,
                        emptyDropDown: this.emptyDropDown,form:this.state.form,onChange: this.onChange})}
                    {renderItemList({isRequired:true,emptyDropDown: this.emptyDropDown,form:this.state.form,onChange: this.onChange,
                        title : "Model", placeholder : "Select", keyItem : "model", parentField : 'vehicle',allFilters: allFilters})}
                </div>
                <div className='grid-50'>
                    {renderItemList({isRequired:true,emptyDropDown: this.emptyDropDown,form:this.state.form,onChange: this.onChange,title : "Year of Registration", placeholder : "Select", keyItem : "year", parentField : 'vehicle',allFilters: allFilters})}
                    {renderItemList({isRequired:true,emptyDropDown: this.emptyDropDown,form:this.state.form,onChange: this.onChange,title : "Version", placeholder : "Select", keyItem : "version", parentField : 'vehicle',allFilters: allFilters})}
                </div>
                <div>
                    {renderDualOptionItem({isRequired:true,onChange: this.onChange,title : "Transmission", placeholder : "Select", 
                        form:this.state.form,
                        options : Object.keys(this.state.form.vehicle.transmission), keyItem : 'transmission', parentField : 'vehicle'})}
                </div>
                <div className='grid-50'>
                    {renderItemList({isRequired:true,emptyDropDown: this.emptyDropDown,form:this.state.form,onChange: this.onChange,title : "Fuel", keyItem  : 'fuel', placeholder : 'Fuel', parentField : 'vehicle',allFilters: allFilters})}
                </div>
                <div className='grid-50'>
                    {renderItemList({isRequired:true,emptyDropDown: this.emptyDropDown,form:this.state.form,onChange: this.onChange,title : "Vehicle Type", placeholder : 'Vehicle Type', keyItem : 'vehicleType', parentField : 'vehicle',allFilters: allFilters})}
                </div>
                <div>
                    {renderDualOptionItem({isRequired:true,onChange: this.onChange,title : "Doors", placeholder : "Select", options : ["2/3", "4/5"], keyItem : "doors", parentField : 'vehicle'})}
                </div>
                <div className='grid-50'>
                    <div>
                        {renderInput({title : "Power",onChange: this.onChange,form:this.state.form,type:'number',isRequired:true, placeholder : "Select", keyItem : 'power', parentField : 'vehicle'})}
                        {renderDualOptionItem({onChange: this.onChange, placeholder : "Select", options : ["HP", "kW"], keyItem : "powerUnit", parentField : 'vehicle'})}
                    </div>
                   {renderInput({isRequired:true,onChange: this.onChange,form:this.state.form,isRequired: true,type:'number',title : "Engine", placeholder : "A unidade esta em cm³, ex: 2000 = 2000cm³"
                    , keyItem : "engine", parentField : 'vehicle'})}
                </div>
                <div className='grid-50'>
                    {renderInput({isRequired:true,onChange: this.onChange,form:this.state.form,title : "Mileage", placeholder : "Select", type : "number", keyItem:'mileage', parentField : 'vehicle'})}
                    {renderInput({isRequired:true,onChange: this.onChange,form:this.state.form,title : "CO2 Emission", placeholder : "Select", type : "number", keyItem:'co2Emission', parentField : 'vehicle'})}
                </div>
                <div className='grid-50'>
                    {renderItemList({isRequired:true,emptyDropDown: this.emptyDropDown,form:this.state.form,onChange: this.onChange,title : "Color", placeholder : "Select", keyItem : "color", parentField : 'vehicle',allFilters: allFilters})}
                </div>
                <div>
                    {renderMultiOptionItem({isRequired:true,form: this.state.form, onChange: this.onChange,title : "Interior Material", options : ["Leather", "Fabric", "Alcantara", "Other"], keyItem : "interiorMaterial", parentField : 'vehicle'})}
                </div>
                <div>
                    {renderDualOptionItem({isRequired:true,onChange: this.onChange,title : "VAT Deductible", placeholder : "Select", options : ["Yes", "No"], keyItem : "vatDeductible", parentField : 'vehicle'})}
                </div>
                <div className='grid-50'>
                    {renderItemList({isRequired:true,emptyDropDown: this.emptyDropDown,form:this.state.form,onChange: this.onChange,title : "Country of Origin", placeholder : "Select", keyItem : "country", parentField : 'vehicle',allFilters: allFilters})}
                </div>
                
                {this.renderPageContinue({page : 2, disabled: !canContinue})}
            </div>
        )
    }

    renderEquipmentPage = () => {
        return (
            <div className='form-content container'>
                {this.renderPageHeader({page : 2, title : "Mark the equipment that your vehicle has"})}
                {renderMultiSelection({onChange: this.onChange,form:this.state.form,title : "Security and Stability  ", options : ["Active Steering", "Alarm", "Predictive Emergency braking System (PEBS)", "Front and Rear Sensors"], keyItem : "security", parentField : 'vehicle'})}
                {renderMultiSelection({onChange: this.onChange,form:this.state.form,title : "Multimedia  ", options : ["Active Steering", "Alarm", "Predictive Emergency braking System (PEBS)", "Front and Rear Sensors"], keyItem : "multimedia", parentField : 'vehicle'})}
                {renderMultiSelection({onChange: this.onChange,form:this.state.form,title : "Interior  ", options : ["Active Steering", "Alarm", "Predictive Emergency braking System (PEBS)", "Front and Rear Sensors"], keyItem : "interior", parentField : 'vehicle'})}
                {renderMultiSelection({onChange: this.onChange,form:this.state.form,title : "Others  ", options : ["Active Steering", "Alarm", "Predictive Emergency braking System (PEBS)", "Front and Rear Sensors"], keyItem : "other", parentField : 'vehicle'})}
                {this.renderPageContinue({page : 3})}
            </div>
        )
    }
    
    renderCarConditionPage = () => {
        return (
            <div className='form-content container'>
                {this.renderPageHeader({page : 3, title : "Vehicle condition"})}
                {renderDualOptionItem({onChange: this.onChange,title : "First Owner", options : ["Yes", "No"], keyItem : "firstOwner", parentField : 'vehicle'})}
                {renderDualOptionItem({onChange: this.onChange,title : "Non-smoking Vehicle", options : ["Yes", "No"], keyItem : "nonSmoking", parentField : 'vehicle'})}
                {renderDualOptionItem({onChange: this.onChange,title : "Service Book", options : ["Yes", "No"], keyItem : "serviceBook", parentField : 'vehicle'})}
                {renderDualOptionItem({onChange: this.onChange,title : "Guarantee", options : ["Yes", "No"], keyItem : "guarantee", parentField : 'vehicle'})}
                {renderDualOptionItem({onChange: this.onChange,title : "Accident-free", options : ["Yes", "No"], keyItem : "accidentFree", parentField : 'vehicle'})}
                {renderDualOptionItem({onChange: this.onChange,title : "Used as a demonstration vehicle", options : ["Yes", "No"], keyItem : "demonstrationVehicle", parentField : 'vehicle'})}
                {renderDualOptionItem({onChange: this.onChange,title : "Used as a rental car", options : ["Yes", "No"],    keyItem : "rentalCar", parentField : 'vehicle'})}
                {renderDualOptionItem({onChange: this.onChange,title : "Used as a taxi", options : ["Yes", "No"], keyItem : "taxi", parentField : 'vehicle'})}
                {renderDualOptionItem({onChange: this.onChange,title : "Used as a driving school car", options : ["Yes", "No"], keyItem : "drivingSchoolCar", parentField : 'vehicle'})}
                {this.renderPageContinue({page : 4})}
            </div>
        )
    }
    
    renderContactInformationPage = () => {
        return (
            <div className='form-content container'>
                {this.renderPageHeader({page : 4, title : "Contact Information"})}
                <div className='grid-50'>
                    {renderInput({onChange: this.onChange,form:this.state.form,title : "First Name", keyItem:'firstName', placeholder : "Enter your first name", parentField : 'contact'})}
                    {renderInput({onChange: this.onChange,form:this.state.form,title : "Last Name", keyItem:'lastName', placeholder : "Enter your last name", parentField : 'contact'})}
                </div>
                <div className='grid-50'>
                    {renderInput({onChange: this.onChange,form:this.state.form,title : "Phone Number", keyItem:'phoneNumber', placeholder : "Enter your phone number", parentField : 'contact', type : 'phoneNumber'})}
                    {renderInput({onChange: this.onChange,form:this.state.form,title : "Email", keyItem:'email', placeholder : "Enter your email", parentField : 'contact'})}
                </div>
                <div className='grid-50'>
                    {renderItemList({emptyDropDown: this.emptyDropDown,form:this.state.form,onChange: this.onChange,title : "Country", placeholder : "Enter your country", parentField : 'contact', keyItem : "country", options : allFilters.country,allFilters: allFilters})}
                    {renderInput({onChange: this.onChange,form:this.state.form,title : "City", keyItem:'ciry',placeholder : "Enter your city", parentField : 'contact'})}
                </div>
                <div className='grid-50'>
                    {renderInput({onChange: this.onChange,form:this.state.form,title : "Postal Code", keyItem:'postalCode',placeholder : "Enter your postal code", parentField : 'contact'})}
                    {renderInput({onChange: this.onChange,form:this.state.form,title : "Street",keyItem:'street', placeholder : "Enter your street", parentField : 'contact'})}
                </div>
                <div className='grid-50'>
                    {renderInput({onChange: this.onChange,form:this.state.form,title : "House Number",keyItem:'houseNumber', placeholder : "Enter your house number", parentField : 'contact'})}
                </div>
                {this.renderPageContinue({page : 5})}
            </div>
        )
    }
    

    renderPhotosPage = () => {
        return (
            <div className='form-content container'>
                {this.renderPageHeader({page : 5, title : "Photos"})}
                <div className='small-container'>
                    <h5 className='title bold-text'> Avatar </h5>
                    <div className='photos-container'>
                        {this.renderUpload({title : "Main Photo", keyItem : "avatar", parentField : 'vehicle'})}
                    </div>
                    <h5 className='title bold-text'> Exterior </h5>
                    <div className='photos-container'>
                        {this.renderUpload({title : "Left Side", keyItem : "leftSideExterior", parentField : 'photos'})}
                        {this.renderUpload({title : "Right Side", keyItem : "rightSideExterior", parentField : 'photos'})}
                        {this.renderUpload({title : "Front", keyItem : "frontExterior", parentField : 'photos'})}
                        {this.renderUpload({title : "Back", keyItem : "backExterior", parentField : 'photos'})}
                    </div>
                </div>
                <div className='small-container'>
                    <h5 className='title bold-text'> Interior </h5>
                    <div className='photos-container'>
                        {this.renderUpload({title : "Left Side", keyItem : "leftSideInterior", parentField : 'photos'})}
                        {this.renderUpload({title : "Right Side", keyItem : "rightSideInterior", parentField : 'photos'})}
                        {this.renderUpload({title : "Front", keyItem : "frontInterior", parentField : 'photos'})}
                        {this.renderUpload({title : "Back", keyItem : "backInterior", parentField : 'photos'})}
                    </div>
                </div>
                <div className='small-container'>
                    <h5 className='title bold-text'> Others </h5>
                    <div className='photos-container'>
                        {this.renderUpload({title : "Other 1", keyItem : "other1", parentField : 'photos'})}
                        {this.renderUpload({title : "Other 2", keyItem : "other2", parentField : 'photos'})}
                    </div>
                </div>
               
                {this.renderPageContinue({page : 6})}
            </div>
        )
    }
    
    renderCarValuationPage = () => {
        const { price, vat } = this.state.form.vehicle;
        return (
            <div className='form-content container'>
                {this.renderPageHeader({page : 6, title : "Car Valuation"})}
                <div className='grid-50'>
                    {renderInput({onChange: this.onChange,form:this.state.form,title : "Price",keyItem:'price', placeholder : "Enter the price", type : "number", parentField : "vehicle"})}
                    {renderInput({onChange: this.onChange,form:this.state.form,title : "VAT", keyItem:'vat',placeholder : "VAT %", type : "number", parentField : "vehicle", defaultOnEmpty: true, defaultValue: 0})}
                </div>
                <div className='small-container'>
                    <h5 className='grey right-text'>Total Price</h5>
                    <h2 className='right-text'> {parseFloat(price)+(parseFloat(price)*parseFloat(vat)/100)} € </h2>
                </div>
                {this.renderPageSubmit()}
            </div>
        )
    }
    
    renderPages = () => {
        switch(this.state.page){
            case 1:
                return this.renderSpecificationPage();
            case 2:
                return this.renderEquipmentPage();
            case 3:
                return this.renderCarConditionPage();
            case 4:
                return this.renderContactInformationPage();
            case 5:
                return this.renderPhotosPage();
            case 6:
                return this.renderCarValuationPage();
            default:
                return null;
        }
    }

    render = () => {
        const { loading, canContinue } = this.state;
        
        return (
            <div className='form-page overflow-hidden'>  
                <>{ (loading) ? <Loading/> : null}</>
                <MetaTags>
                    <title> Versicar </title>
                    <meta name="description" content="Buy your best car" />
                    <meta property="og:title" content="Versicar" />
                </MetaTags>
                <Header />
                <div className='grid-form grid'>
                    <div className='left padding'>
                        <div className='box-container box-background no-border container-form-left'>
                            <h5 className='grey'>
                                Sell car
                            </h5>
                            {list.map((item, index) => this.renderPageNumber(item, index))}
                        </div>
                    </div>
                    <div className='right padding'>
                        {this.renderPages()}
                    </div>
                </div>
            </div>
            )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }
  
  export default connect(mapStateToProps)(SellFormPage);
